import React, { useState, useEffect } from "react";
import { MenuNavigation } from "../eComm/components/MenuNavigation";
import { ShowCase } from "../eComm/components/pages/homepage/ShowCase";
import { DiscoverMenu } from "../eComm/components/pages/homepage/DiscoverMenu";
import { HomepageSlider } from "../eComm/components/pages/homepage/HomepageSlider";
import { Footer } from "../eComm/components/Footer";
import { useCookies } from "react-cookie";
import ReactDOM from "react-dom";
import { JsonLd } from "react-schemaorg";
import { Helmet, HelmetProvider } from "react-helmet-async";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from "@material-ui/core";
import BottomLanding from "../eComm/components/BottomLanding";
import "bootstrap/dist/css/bootstrap.min.css";
import config from "../../config";

const restaurantName = process.env.REACT_APP_RESTAURANT_NAME;
const restaurantURL = process.env.REACT_APP_SITE;
const phoneNumber = process.env.REACT_APP_CONTACT_US_PHONE;
const enableKiosk = process.env.REACT_APP_ENABLE_EKIOSK;
const tagline = process.env.REACT_APP_RESTAURANT_TAGLINE;
const zipCode = process.env.REACT_APP_ZIP_CODE;
const siteMedSrc = process.env.REACT_APP_MEDIA_SRC;
const streetAddress = process.env.REACT_APP_STREET_ADDRESS;
const addressLocality = process.env.REACT_APP_ADDRESS_LOCALITY;
const addressRegion = process.env.REACT_APP_ADDRESS_REGION;
const dayOfWeekLine1 = process.env.REACT_APP_DAYOFWEEK_LINE1;
const opensLine1 = process.env.REACT_APP_OPENS_LINE1;
const closesLine1 = process.env.REACT_APP_CLOSES_LINE1;
const dayOfWeekLine2 = process.env.REACT_APP_DAYOFWEEK_LINE2;
const opensLine2 = process.env.REACT_APP_OPENS_LINE2;
const closesLine2 = process.env.REACT_APP_CLOSES_LINE2;
const geoCoordinatesLat = process.env.REACT_APP_GEOCOORDINATES_LAT;
const geoCoordinatesLong = process.env.REACT_APP_GEOCOORDINATES_LONG;

export const HomepageLanding = () => {
  const [landingContent, setlandingContent] = useState("");

  useEffect(() => {
    const landingContentText = config.landingcontent;

    setlandingContent(landingContentText);
  }, []);

  const schema = {
    "@context": "https://schema.org",
    "@type": "Restaurant",
    name: { restaurantName },
    address: {
      "@type": "PostalAddress",
      streetAddress: streetAddress,
      addressLocality: addressLocality,
      postalCode: zipCode,
      addressRegion: addressRegion,
      addressCountry: "US",
    },
    url: restaurantURL,
    telephone: phoneNumber,
    priceRange: "$5-100",
    openingHoursSpecification: [
      {
        "@type": "OpeningHoursSpecification",
        dayOfWeek: [dayOfWeekLine1],
        opens: opensLine1,
        closes: closesLine1,
      },
      {
        "@type": "OpeningHoursSpecification",
        dayOfWeek: [dayOfWeekLine2],
        opens: opensLine2,
        closes: closesLine2,
      },
    ],
    geo: {
      "@type": "GeoCoordinates",
      latitude: geoCoordinatesLat,
      longitude: geoCoordinatesLong,
    },
    image: [`url(${siteMedSrc}logo.png)`],
  };

  const [openWelcome, setOpenWelcome] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [cookieSICName, setCookieSICName, removeCookieSICName] = useCookies([
    "sic_name",
  ]);

  const getCookie = (key) => {
    var keyValue = document.cookie.match("(^|;) ?" + key + "=([^;]*)(;|$)");
    return keyValue ? keyValue[2] : null;
  };

  const setCookie = (key, value) => {
    const expires = new Date();
    expires.setTime(expires.getTime() + 365 * 24 * 60 * 60 * 1000);
    document.cookie =
      key + "=" + value + ";expires=" + expires.toUTCString() + ";path=/";
  };

  // useEffect(() => {
  //   var verifyOrderProcess = new EventSource(
  //     "https://ecommv2.servingintel.com/postpayment/verifyorderprocess/" +
  //     siteId +
  //     "/" +
  //     getCookie("orderId") +
  //     "?server_id=" +
  //     serverId
  //   );
  //   verifyOrderProcess.onmessage = function (event) {
  //     if (event.data === 1) {
  //       verifyOrderProcess.close();
  //       setCookie("orderId", "");
  //     }
  //   };
  // }, []);

  useEffect(() => {
    if (cookieSICName) {
      //setOpenWelcome(true);
    }
  }, []);

  const handleCloseWelcome = () => {
    setOpenWelcome(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Dialog
        open={openWelcome}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        expires={365}
        id="modalAlert"
      >
        <DialogTitle id="alert-dialog-title">{"Greetings!"}</DialogTitle>
        <DialogContent>
          <DialogContentText
            style={{ "text-align": "left" }}
            id="alert-dialog-description"
          >
            <h5>
              AJ's Sliders is remodeling and rebranding for 4 weeks as{" "}
              {restaurantName}.
            </h5>

            <h5>
              In the meantime, please visit AJ's Java Joint{" "}
              <a
                href="https://ajsjavajoint.com"
                className="f-link"
                target="_blank"
              >
                https://ajsjavajoint.com
              </a>{" "}
              in South Elgin to continue to enjoy nutritious coffee and
              delicious food. We'll be back in full force soon, so see you all
              there!
            </h5>

            <b>{cookieSICName.sic_name}</b>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ "text-align": "center", display: "block" }}>
          <Button onClick={handleCloseWelcome} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      {enableKiosk === "0" ? (
        <div className="App">
          <MenuNavigation />
          <HomepageSlider />
          <ShowCase />
          <DiscoverMenu />
          <Footer />
        </div>
      ) : (
        <div className="App">
          <HomepageSlider />
          <BottomLanding />
        </div>
      )}
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            {restaurantName}
            {":"} {tagline}
          </title>
          <link rel="canonical" href={restaurantURL} />
          <meta name="description" content={`${landingContent.description}`} />
          <meta name="keywords" content={`${landingContent.keywords}`} />
          <meta
            property="og:title"
            content={`${restaurantName}: ${landingContent.title}`}
          />
          <meta
            property="og:description"
            content={`${landingContent.description}`}
          />
          <meta
            property="twitter:title"
            content={`${restaurantName}: ${landingContent.title}`}
          />
          <meta
            property="twitter:description"
            content={`${landingContent.description}`}
          />
          <script type="application/ld+json">{JSON.stringify(schema)}</script>
        </Helmet>
      </HelmetProvider>
    </>
  );
};
