const config = {
  // Landing Page
  landingcontent: {
    title: "Angelo's II Monongahela: Savor Italy's Finest",
    subtitle:
      "Exceptional Menu, Taps Galore, Impeccable Service, and a Spectacle of Holiday Magic!",
    description:
      "Locally owned restaurant offering quality food at a good price. Enjoy dine-in or take-out for delicious sandwiches, pastas, and more!",
    keywords:
      "Italian cuisine, Antipasto salads, Gnocchi, Cheese pizza, Authentic flavors, Delightful options, Daily specials, Remodeled bar, Dinning room, Fun displays, Holiday magic, Riverside revelry, Delectable delights, Dazzling decorations, Culinary journey, Passionate crafting, Daily food specials, Homemade, Takeout, Impeccable service, Six packs, To-go options, Quality food, Locally owned, Sandwiches, Pastas, Social media, Facebook, Instagram, TikTok, Yelp, TripAdvisor, YouTube, Exceptional menu, Taps galore, Holiday haven, Same-day pickup, Same-day delivery, Pickup options, Dine-in experience, Delivery services, Delivery charge, Free delivery, Service fee",
  },

  // About Us Page
  aboutcontent: {
    title:
      "Angelo's II Monongahela: A Culinary Journey Through Italian Excellence",
    subtitle: "Indulge in Authentic Flavors and Memorable Experiences",
    description:
      "Embark on a delightful culinary adventure at Angelo's, where we take pride in crafting an array of authentic Italian dishes, including our renowned antipasto salads, gnocchi, and cheese pizza. Our inviting atmosphere, paired with warm hospitality, transforms every visit into a celebration of traditional flavors and culinary excellence.",
    keywords:
      "Italian cuisine, Antipasto salads, Gnocchi, Cheese pizza, Authentic flavors, Delightful options, Daily specials, Remodeled bar, Dinning room, Fun displays, Holiday magic, Riverside revelry, Delectable delights, Dazzling decorations, Culinary journey, Passionate crafting, Daily food specials, Homemade, Takeout, Impeccable service, Six packs, To-go options, Quality food, Locally owned, Sandwiches, Pastas, Social media, Facebook, Instagram, TikTok, Yelp, TripAdvisor, YouTube, Exceptional menu, Taps galore, Holiday haven, Same-day pickup, Same-day delivery, Pickup options, Dine-in experience, Delivery services, Delivery charge, Free delivery, Service fee",
    header: "Discover the Essence of Italian Culinary Excellence",
    message:
      "Savor the rich and authentic flavors of Italy at Angelo's – where each bite is a journey, and every visit is a celebration of delectable dishes and warm hospitality.",
  },
  aboutbusiness: {
    paragraph1:
      "Embark on a culinary journey at Angelo's, where our passion for Italian cuisine takes center stage. Indulge in the rich and authentic flavors that define our menu, featuring a tempting array of dishes crafted with care. From the delightful taste of our antipasto salads to the savory goodness of our gnocchi and cheese pizza, each bite is a celebration of traditional Italian culinary excellence. At Angelo's, we take pride in creating an inviting atmosphere where every visit becomes a memorable experience filled with delectable options and warm hospitality.",
    paragraph2:
      "Nestled in a festive locale, our establishment transforms into a fun haven during the holidays, providing a picturesque setting close to the river. Our menu boasts an impressive selection, ensuring there's not a single uninspiring item. Enjoy the convivial ambiance with a great selection of draft beer, and rest assured that our service is consistently on point. Our commitment to creating a festive atmosphere shines through in our holiday decorations, garnering national attention each year as the entire building is adorned with enchanting displays, making Angelo's a must-visit destination for seasonal cheer and delectable delights.",
  },
  littleaboutus: {
    paragraph1:
      "Embark on a culinary journey at Angelo's, where our passion for Italian cuisine takes center stage. Indulge in the rich and authentic flavors that define our menu, featuring a tempting array of dishes crafted with care. From the delightful taste of our antipasto salads to the savory goodness of our gnocchi and cheese pizza, each bite is a celebration of traditional Italian culinary excellence. At Angelo's, we take pride in creating an inviting atmosphere where every visit becomes a memorable experience filled with delectable options and warm hospitality.",
    paragraph2:
      "Nestled in a festive locale, our establishment transforms into a fun haven during the holidays, providing a picturesque setting close to the river. Our menu boasts an impressive selection, ensuring there's not a single uninspiring item. Enjoy the convivial ambiance with a great selection of draft beer, and rest assured that our service is consistently on point. Our commitment to creating a festive atmosphere shines through in our holiday decorations, garnering national attention each year as the entire building is adorned with enchanting displays, making Angelo's a must-visit destination for seasonal cheer and delectable delights.",
    paragraph3:
      "Fun place for the holidays. Close to the river. Not a bad thing on the menu. Great selection of draft beer. Service is always on point! The holiday decorations make national news every year with nearly the entire building covered in decorations.",
  },

  // Contact Page
  contactcontent: {
    title:
      "Reach out to us for an authentic Italian dining experience by the riverbank at Angelo's II",
    description:
      "Experience joy by the riverbank at Angelo's II Italian Restaurant. A locally owned gem offering quality food at a good price. Enjoy dine-in or take-out for delicious sandwiches, pastas, and more!",
    keywords:
      "Angelos II Italian Restaurant, Contact Us, Address, Email, Phone, Social Media, Facebook, Instagram, Twitter, Google Maps, Questions, Assistance, Italian Dining, Authentic Experience, Exceptional Service, Same Day Pickup, Same Day Delivery, Delivery Charge, Contact Email, Contact Phone, Free Wi-Fi, Fast Service, Location, Maps, Reach Out, Dine-in, Take-out, Sandwiches, Pastas, Riverbank, Holiday Magic, Contact Information, Contact Form, Daily Specials, Remodeled Bar, Six Packs To Go, Contact Details, Feedback, Inquiry, Connect, Hours of Operation, Location Map, Quick Response, Customer Support, Authentic Italian, Social Links, Map Embed, Authentic Italian Dining, Assistance, Restaurant Contact, Social Connect, Authentic Experience, Location Information.",
    message:
      "Have questions or need assistance? Reach out to us for an authentic Italian dining experience by the riverbank at Angelo's II. Exceptional menu, taps galore, impeccable service, and a spectacle of holiday magic await you!",
  },

  // Photo Gallery Page
  gallerycontent: {
    title: "Capturing Culinary Delights at Angelos II Italian Restaurant",
    description:
      "Explore the enchanting world of Angelos II Italian Restaurant through our photo gallery. Immerse yourself in the vibrant colors of our delectable dishes, the festive ambiance of our remodeled bar and dining room, and the joyous moments shared by our patrons. Each photo tells a story of passion for Italian cuisine and the unique experience offered at our locally-owned establishment.",
    keywords:
      "Italian restaurant gallery, culinary delights, festive ambiance, remodeled dining room, delectable dishes, local dining, joyful moments, Angelos II visuals, food photography, visual feast, Monongahela, Pennsylvania.",
    message:
      "Journey through the visual delights of Angelos II Italian Restaurant – where every image is a taste of the culinary joy we offer.",
  },

  // Menu Page
  ordercontent: {
    title: "Explore your favorite menu items, right from home",
    description:
      "Indulge in a culinary adventure at Angelos II Italian Restaurant with our thoughtfully crafted menu. From savory sandwiches to authentic pastas, each dish is a celebration of flavor and quality. Explore our diverse offerings, whether you're dining in or opting for convenient takeout. Join us in savoring Italy's finest right here in Monongahela, Pennsylvania.",
    keywords:
      "Italian restaurant menu, culinary creations, authentic pastas, savory sandwiches, dine-in menu, takeout options, Italian gastronomy, flavor celebration, Monongahela dining, food variety, gastronomic journey.",
    message:
      "Embark on a culinary journey with the Angelos II Italian Restaurant menu—where passion meets palate.",
  },

  // Eloyalty Page
  eloyaltycontent: {
    title: "Rewarding Your Loyalty: Angelos II eLoyalty Program",
    description:
      "Join the Angelos II eLoyalty Program and unlock a world of exclusive rewards! Our locally-owned restaurant is thrilled to show appreciation for your loyalty. Earn points with every visit, enjoy special discounts, and access member-only perks. Whether you're a frequent diner or an occasional visitor, our eLoyalty Program is designed to make your experience even more delightful.",
    keywords:
      "eLoyalty Program, exclusive rewards, loyalty benefits, local dining perks, frequent diner program, member-only discounts, Angelos II loyalty, dining experience, loyalty points, restaurant rewards, Monongahela.",
    message:
      "Experience dining like never before with Angelos II eLoyalty—where your loyalty is celebrated with every visit.",
  },
};

export default config;
