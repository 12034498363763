import React from 'react';

const addressMap = process.env.REACT_APP_ADDRESS_MAP;

export const AddressMap = () => {
  return (
    <div className="google-map-code">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3047.291781948106!2d-79.925124!3d40.20257199999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8834fd51807c1c0f%3A0x71450a1a46adf497!2sAngelo&#39;s%20II%20Monongahela!5e0!3m2!1sen!2sph!4v1701869681115!5m2!1sen!2sph" width="100%" height="450" frameborder="0" style={{ border: 0 }} allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
    </div>
  );
}